import { useState, useEffect, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { OrderStatus, IOrder } from "../../../types/order/IOrder";
import { getDocs, query, collection, where } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { Location } from "../../../models";
import { Tooltip } from "flowbite-react";
import { HiOutlineCalendar } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { HiCheck } from "react-icons/hi";
import {
  HiOutlineClock,
  HiOutlineUserGroup,
  HiOutlineBadgeCheck,
} from "react-icons/hi";

import { Timestamp } from "../../../types";
import {
  noCompletedOrders,
  noUpcomingOrders,
} from "../../atoms/Icons/illustrations";
import { Button } from "flowbite-react";
import { checkCirlce, waitCircle } from "../../atoms/Icons/Icons";
import { SimpleList } from "../SimpleList";
import InfoCard from "../Cards/InfoCard";
export interface LocationDetailsProps {
  location: Location | undefined;
  sendInvoice?: () => void;
  edit: () => void;
  delete: () => void;
}

export const LocationDetails: FC<LocationDetailsProps> = (props) => {
  const { t } = useTranslation(["common", "location"]);
  const [hasCopied, setHasCopied] = useState(false);
  const [completedOrders, setCompletedOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [upcomingOrders, setUpcomingOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [hasLoadedMoreUpcoming, setHasLoadedMoreUpcoming] = useState(false);
  const [hasLoadedMoreCompleted, setHasLoadedMoreCompleted] = useState(false);
  const [allCompletedOrdersCount, setAllCompletedOrdersCount] = useState(0);
  const [allUpcomingOrdersCount, setAllUpcomingOrdersCount] = useState(0);

  const [activeTab, setActiveTab] = useState<"completed" | "upcoming">(
    "completed",
  );
  const [completedPage, setCompletedPage] = useState(1);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [uniqueCustomerCount, setUniqueCustomerCount] = useState(0);

  const ordersPerPage = 5;

  const formatTimestamp = (
    timestamp: Timestamp | string | undefined,
  ): string => {
    if (!timestamp) return t("location:unknown_date");
    if (typeof timestamp === "string") return timestamp;
    return new Date(timestamp.seconds * 1000).toLocaleDateString(); // Converts Firestore Timestamp to a readable date
  };

  useEffect(() => {
    const fetchUniqueCustomerCount = async () => {
      if (!props.location || !props.location.workspaceId) return;

      try {
        const ordersQuery = query(
          collection(firestore, "orders"),
          where("workspaceId", "==", props.location.workspaceId),
        );

        const querySnapshot = await getDocs(ordersQuery);

        const allMatchedOrders = querySnapshot.docs
          .map((doc) => doc.data() as IOrder)
          .filter((order) =>
            order.route.some((routeItem) => {
              const routeLocation = routeItem.location;

              if (!routeLocation || !props.location) return false;

              return (
                routeLocation.addressLine?.trim().toLowerCase() ===
                  props.location.addressLine?.trim().toLowerCase() &&
                routeLocation.city?.trim().toLowerCase() ===
                  props.location.city?.trim().toLowerCase() &&
                routeLocation.postCode?.trim() ===
                  props.location.postCode?.trim()
              );
            }),
          );

        const uniqueCustomers = new Set(
          allMatchedOrders.map((order) => order.customer?.name || "Unknown"),
        );

        setUniqueCustomerCount(uniqueCustomers.size);
      } catch (error) {
        console.error("Error fetching unique customer count:", error);
      }
    };

    fetchUniqueCustomerCount();
  }, [props.location]);

  const fetchOrdersForLocation = async (
    page: number,
    type: "completed" | "upcoming",
  ) => {
    if (!props.location || !props.location.workspaceId) return;

    try {
      const ordersQuery = query(
        collection(firestore, "orders"),
        where("workspaceId", "==", props.location.workspaceId),
      );

      const querySnapshot = await getDocs(ordersQuery);

      const allMatchedOrders = querySnapshot.docs
        .map((doc) => ({ ...(doc.data() as IOrder), orderId: doc.id })) // Correctly typecast the orders
        .filter((order) =>
          order.route.some((routeItem) => {
            const routeLocation = routeItem.location;

            if (!routeLocation || !props.location) return false;

            return (
              routeLocation.addressLine?.trim().toLowerCase() ===
                props.location.addressLine?.trim().toLowerCase() &&
              routeLocation.city?.trim().toLowerCase() ===
                props.location.city?.trim().toLowerCase() &&
              routeLocation.postCode?.trim() === props.location.postCode?.trim()
            );
          }),
        );

      const isCompleted = (status: OrderStatus) =>
        status === OrderStatus.Completed;
      const isUpcoming = (status: OrderStatus) =>
        status === OrderStatus.New ||
        status === OrderStatus.Scheduled ||
        status === OrderStatus.InProgress;

      const completed = allMatchedOrders
        .filter((order) => isCompleted(order.status))
        .map((order) => ({
          title: order.customer?.name ?? t("location:unknown_customer"),
          value: `From ${
            order.route[0]?.location?.addressLine ??
            t("location:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ??
            t("location:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.orderId,
        }))
        .sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        ); // Sort by latest date

      const upcoming = allMatchedOrders
        .filter((order) => isUpcoming(order.status))
        .map((order) => ({
          title: order.customer?.name ?? t("location:unknown_customer"),
          value: `From ${
            order.route[0]?.location?.addressLine ??
            t("location:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ??
            t("location:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.orderId,
        }))
        .sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        ); // Sort by latest date

      const start = (page - 1) * ordersPerPage;
      const end = start + ordersPerPage;

      if (type === "completed") {
        if (page === 1) setAllCompletedOrdersCount(completed.length);
        const paginated = completed.slice(start, end);
        setCompletedOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      } else {
        if (page === 1) setAllUpcomingOrdersCount(upcoming.length);
        const paginated = upcoming.slice(start, end);
        setUpcomingOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      }
    } catch (error) {
      console.error("Error fetching orders from Firestore:", error);
    }
  };

  useEffect(() => {
    fetchOrdersForLocation(1, "completed");
    fetchOrdersForLocation(1, "upcoming");
  }, [props.location]);

  const loadMoreOrders = (type: "completed" | "upcoming") => {
    if (type === "completed") {
      const nextPage = completedPage + 1;
      setCompletedPage(nextPage);
      fetchOrdersForLocation(nextPage, "completed");
    } else {
      const nextPage = upcomingPage + 1;
      setUpcomingPage(nextPage);
      fetchOrdersForLocation(nextPage, "upcoming");
    }
  };

  return (
    <>
      {/* Location Header */}

      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {props.location?.displayName}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.location?.displayName?.toString() ?? "",
                    );
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-start gap-4 my-6">
        {" "}
        <InfoCard
          icon={<HiOutlineBadgeCheck className="h-7 w-7 text-lgb-grey-600" />}
          title={t("location:completed_orders")}
          description={`${allCompletedOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-green-100"
        />
        <InfoCard
          icon={<HiOutlineClock className="h-7 w-7 text-lgb-grey-600" />}
          title={t("location:upcoming_orders")}
          description={`${allUpcomingOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-yellow-100"
        />
        <InfoCard
          icon={<HiOutlineUserGroup className="h-7 w-7 text-lgb-grey-600" />}
          title={t("location:unique_customers")}
          description={`${uniqueCustomerCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-blue-100"
        />
      </div>

      <SimpleList
        items={[
          {
            title: t("location:details.address"),
            value: props.location?.addressLine,
          },
          {
            title: t("location:details.city"),
            value: props.location?.city,
          },
          {
            title: t("location:details.postCode"),
            value: props.location?.postCode,
          },
          {
            title: t("location:details.description"),
            value: props.location?.description,
          },
          {
            title: t("location:details.countryCode"),
            value: props.location?.countryCode,
          },
        ]}
      />

      {/* Tabs */}
      <div className="mt-6">
        <p className="text-xl font-medium dark:text-lgb-grey-100 mb-4">
          {t("location:related_orders")}
        </p>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("completed")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "completed"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("location:completed_orders")}
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("upcoming")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "upcoming"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("location:upcoming_orders")}
              </button>
            </li>
          </ul>
        </div>

        {/* Order List */}
        <AnimatePresence mode="wait">
          {activeTab === "completed" ? (
            <motion.div
              key="completed"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              {completedOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noCompletedOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("location:no_completed_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {completedOrders
                      .slice(
                        0,
                        hasLoadedMoreCompleted
                          ? completedOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={
                            hasLoadedMoreCompleted
                              ? undefined // Prevent exit animation for "Show Less"
                              : { opacity: 0, y: -20 } // Apply exit animation for actual removal
                          }
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={`/orders/all?id=${orderId}`}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue h-full hover:shadow-customHover dark:hover:bg-lgb-grey-600 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                <div className="flex-shrink-0">
                                  {checkCirlce}
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title ?? t("location:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 whitespace-nowrap">
                                {stopDate
                                  ? Timestamp.toDisplayDate(stopDate)
                                  : t("location:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button for Completed */}
                  {completedOrders.length < allCompletedOrdersCount &&
                    !hasLoadedMoreCompleted && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("completed");
                          setHasLoadedMoreCompleted(true); // Track that user loaded more
                        }}
                      >
                        {t("location:load_more_completed")}
                      </Button>
                    )}
                  {hasLoadedMoreCompleted && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setCompletedOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreCompleted(false); // Reset the "loaded more" state
                        setCompletedPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("location:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="upcoming"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              {upcomingOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noUpcomingOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("location:no_upcoming_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {upcomingOrders
                      .slice(
                        0,
                        hasLoadedMoreUpcoming
                          ? upcomingOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={`/orders/all?id=${orderId}`}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue h-full hover:shadow-customHover dark:hover:bg-lgb-grey-600 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                <div className="flex-shrink-0">
                                  {waitCircle}
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title ?? t("location:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 whitespace-nowrap">
                                {stopDate
                                  ? Timestamp.toDisplayDate(stopDate)
                                  : t("location:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button */}
                  {upcomingOrders.length < allUpcomingOrdersCount &&
                    !hasLoadedMoreUpcoming && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("upcoming");
                          setHasLoadedMoreUpcoming(true); // Track that user loaded more
                        }}
                      >
                        {t("location:load_more_upcoming")}
                      </Button>
                    )}
                  {hasLoadedMoreUpcoming && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setUpcomingOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreUpcoming(false); // Reset the "loaded more" state
                        setUpcomingPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("location:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
