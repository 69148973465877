/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogNotify,
  OrderDetails,
  SplitTable,
  StatusBadge,
} from "../../../components";
import { Order } from "../../../models";
import { ITableRow, Timestamp } from "../../../types";
import { HiChevronRight } from "react-icons/hi";
import pdfIcon from "../../../assets/icons/pdfIcon.svg";
import { Button } from "flowbite-react";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown } from "react-icons/hi";
import { IOrder, OrderStatus } from "../../../types/order/IOrder";
import { useDrivers } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { EditDriver } from "./EditDriver";
import { DeleteOrderIcon, StartOrderIcon } from "../../atoms/Icons/Icons";
import { EditRoute } from "./EditRoute";
import { UpdateStatus } from "./UpdateStatus";
import { SendDeliveryLetter } from "./SendDeliveryLetter";
import { EditCargo } from "./EditCargo";
import { cancelOrder as cancelOrderApi } from "../../../api";

import { useUserSession } from "../../../context/UserContext";
import { useCache } from "../../../context/CacheContext";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { useNavigate, useLocation } from "react-router-dom";

interface OrderListProp {
  orders: Order[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
}

export const OrderList: FC<OrderListProp> = function (props: OrderListProp) {
  const { t } = useTranslation(["common", "orders", "validation"]);
  const [openedOrder, setOpenedOrder] = useState<Order | undefined>(undefined);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Order[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [showStartOrderDialog, setShowStartOrderDialog] = useState(false);
  const [showEditDriver, setShowEditDriver] = useState(false);
  const [showEditRoute, setShowEditRoute] = useState(false);
  const [showEditCargo, setShowEditCargo] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [showDeleteOrderDialog, setShowDeleteOrderDialog] = useState(false);
  const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false);
  const [showDeliveryLetterDialog, setShowDeliveryLetterDialog] =
    useState(false);
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const { updateCacheKey } = useCache();
  const splitTableRef = useRef<SplitTableRef>(null);

  const query = useDrivers(activeWorkspace?.workspaceId ?? "");

  const drivers = useMemo(() => query.data ?? [], [query]);

  const navigate = useNavigate(); // Add navigate
  const location = useLocation(); // Add location

  const updateOpenedOrder = (order: Order | undefined) => {
    setOpenedOrder(order);
    if (order !== undefined) {
      setHideColumns(true);
      props.isOpen(true);
      // Update the URL with the order ID
      navigate(`/orders/all?id=${order.id}`, { replace: true });
      splitTableRef.current?.setSplit(true);
    } else {
      setHideColumns(false);
      props.isOpen(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("id");
    if (orderId) {
      const matchingOrder = props.orders.find((order) => order.id === orderId);
      if (matchingOrder) {
        updateOpenedOrder(matchingOrder);
      }
    }
  }, [location.search, props.orders]);

  useEffect(() => {
    if (!props.isLoading && props.orders) {
      if (searchText.length) {
        let companies =
          props.orders?.filter(
            (x) =>
              x.customer.isCompany &&
              x.customer.name?.toLowerCase().includes(searchText.toLowerCase()),
          ) || [];
        let privatePersons =
          props.orders?.filter(
            (x) =>
              !x.customer.isCompany &&
              (x.customer.firstName + " " + x.customer.lastName)
                ?.toLowerCase()
                .includes(searchText.toLowerCase()),
          ) || [];
        setSearchResults(companies.concat(privatePersons));
      } else {
        setSearchResults(props.orders || []);
      }
    }
  }, [searchText, props.orders, props.isLoading]);

  useEffect(() => {
    if (!props.isLoading && searchResults) {
      setTableRows(
        searchResults.map((order) => {
          return {
            id: order.id,
            isActive: order.id === openedOrder?.id,
            onRowClickData: order,
            cells: [
              {
                id: "customer",
                children: order.customer.isCompany
                  ? order.customer.name
                  : order.customer.firstName + " " + order.customer.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "status",
                children: <StatusBadge status={order.status} />,
                showOnSmallScreen: true,
              },
              {
                id: "date",
                children: Timestamp.toDisplayDate(order.route.at(0)?.stopDate),
              },
              {
                id: "from",
                children: order?.route[0].location.addressLine || "",
                hidden: hideColumns,
              },
              {
                id: "to",
                children:
                  order?.route[order.route.length - 1].location.addressLine ||
                  "",
                hidden: hideColumns,
              },
              {
                id: "open",
                children: <HiChevronRight size={20} />,
                hidden: hideColumns,
              },
            ],
          };
        }),
      );
    }
  }, [props.isLoading, searchResults, t, openedOrder, hideColumns]);

  const cancelOrder = async () => {
    if (openedOrder) {
      setIsLoading(true);
      await cancelOrderApi(
        userSession?.token.accessToken ?? "",
        activeWorkspace?.workspaceId ?? "",
        openedOrder.id,
      ).then((res) => {
        updateOpenedOrder(res);
        updateCacheKey();
        setShowCancelOrderDialog(!showCancelOrderDialog);
      });
      setIsLoading(false);
    }
  };
  const update = async (partial: Partial<IOrder>) => {
    if (openedOrder) {
      setIsLoading(true);
      let res = await Order.update(openedOrder, partial);
      updateOpenedOrder(res);
      updateCacheKey();
      setIsLoading(false);
    }
  };
  const deleteOrder = () => {
    if (openedOrder) {
      setIsLoading(true);
      Order.delete(openedOrder);
      updateCacheKey();
      setShowDeleteOrderDialog(!showDeleteOrderDialog);
      if (splitTableRef.current) {
        splitTableRef.current.setSplit(false); //Close split table
      }
      updateOpenedOrder(undefined);
      setIsLoading(false);
    }
  };
  const sendDeliveryLetter = (email: string) => {
    console.log("Send delivery letter to", email);
  };

  const downloadPDF = () => {};

  return (
    <>
      <SplitTable
        ref={splitTableRef}
        searchable
        isLoading={props.isLoading}
        tableRows={tableRows}
        onRowClick={(order: Order | undefined) => updateOpenedOrder(order)}
        updateTable={(val) => setSearchText(val)}
        content={
          <OrderDetails
            editCargo={() => setShowEditCargo(true)}
            order={openedOrder}
            drivers={drivers}
          />
        }
        topRightContent={
          <div className="flex items-center">
            <Button size="sm" color="light">
              <img src={pdfIcon} alt="PDF icon" className="mr-2" />
              {t("common:download_pdf")}
            </Button>
            {openedOrder &&
              (openedOrder.status !== OrderStatus.Cancelled ||
                userSession?.user.isAdmin) && (
                <DropdownButton
                  placement="bottom-end"
                  buttonText={t("orders:actions")}
                  buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                  items={[
                    ...([
                      OrderStatus.Draft,
                      OrderStatus.New,
                      OrderStatus.Scheduled,
                    ].includes(openedOrder?.status)
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:scheduled_orders.start")}
                              </p>
                            ),
                            onClick: () => {
                              setShowStartOrderDialog(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_cargo")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditCargo(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_route")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditRoute(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {openedOrder.driverId
                                  ? t("orders:scheduled_orders.change_driver")
                                  : t("orders:scheduled_orders.add_driver")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditDriver(true);
                            },
                          },
                        ]
                      : []),
                    ...(openedOrder?.status === OrderStatus.InProgress
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_cargo")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditCargo(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_route")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditRoute(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                                {t("orders:ongoing_orders.cancel")}
                              </p>
                            ),
                            onClick: () => {
                              setShowCancelOrderDialog(true);
                            },
                          },
                        ]
                      : []),

                    ...(openedOrder?.status === OrderStatus.Completed
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:completed_orders.download_pdf")}
                              </p>
                            ),
                            onClick: () => {
                              downloadPDF();
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t(
                                  "orders:completed_orders.send_delivery_letter",
                                )}
                              </p>
                            ),
                            onClick: () => {
                              setShowDeliveryLetterDialog(true);
                            },
                          },
                        ]
                      : []),
                    ...(userSession?.user.isAdmin ||
                    openedOrder?.status === OrderStatus.InProgress
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.update_status")}
                              </p>
                            ),
                            onClick: () => {
                              setShowUpdateStatus(true);
                            },
                          },
                        ]
                      : []),
                    ...(userSession?.user.isAdmin ||
                    [
                      OrderStatus.Draft,
                      OrderStatus.New,
                      OrderStatus.Scheduled,
                    ].includes(openedOrder?.status)
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                                {t("orders:all_orders.delete")}
                              </p>
                            ),
                            onClick: () => {
                              setShowDeleteOrderDialog(true);
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              )}
          </div>
        }
        tableHeaders={[
          {
            id: "customer",
            children: t("common:customer"),
            showOnSmallScreen: true,
          },
          {
            id: "status",
            children: t("common:status"),
            showOnSmallScreen: true,
          },
          {
            id: "date",
            children: t("common:date"),
          },
          {
            id: "from",
            children: t("common:from"),
            hidden: hideColumns,
          },
          {
            id: "to",
            children: t("common:to"),
            hidden: hideColumns,
          },
          {
            id: "open",
            children: <></>,
            hidden: hideColumns,
          },
        ]}
      />
      {openedOrder && (
        <>
          {drivers.length && (
            <EditDriver
              order={openedOrder}
              drivers={drivers}
              isLoading={isLoading}
              show={showEditDriver}
              type="add"
              edit={(driverId) => {
                update({ driverId: driverId });
              }}
              close={() => setShowEditDriver(false)}
            ></EditDriver>
          )}

          <EditRoute
            route={[...openedOrder.route]}
            show={showEditRoute}
            edit={(route) => {
              update({ route: route });
            }}
            close={() => setShowEditRoute(false)}
          ></EditRoute>

          <EditCargo
            order={openedOrder}
            isLoading={isLoading}
            show={showEditCargo}
            edit={(cargo) => {
              update({ cargo: cargo });
            }}
            close={() => setShowEditCargo(false)}
          ></EditCargo>

          <UpdateStatus
            show={showUpdateStatus}
            isLoading={isLoading}
            currentStatus={openedOrder?.status}
            toggleModal={() => setShowUpdateStatus(!showUpdateStatus)}
            confirmButton={(newStatus: OrderStatus) => {
              update({ status: newStatus });
              setShowUpdateStatus(!showUpdateStatus);
            }}
          />
          <SendDeliveryLetter
            show={showDeliveryLetterDialog}
            isLoading={isLoading}
            toggleModal={() =>
              setShowDeliveryLetterDialog(!showDeliveryLetterDialog)
            }
            confirmButton={(email: string) => {
              sendDeliveryLetter(email);
              setShowDeliveryLetterDialog(!showDeliveryLetterDialog);
            }}
          />
          <DialogNotify
            show={showStartOrderDialog}
            isLoading={isLoading}
            icon={<StartOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="primaryPurple"
            buttonConfirmText={t("orders:scheduled_orders.start")}
            title={t("orders:scheduled_orders.start_title")}
            text={t("orders:scheduled_orders.start_description")}
            toggleModal={() => setShowStartOrderDialog(!showStartOrderDialog)}
            confirmButton={() => {
              const routeWithUpdatedDate = [...openedOrder.route];
              routeWithUpdatedDate[0].stopDate = Timestamp.now();
              update({
                status: OrderStatus.InProgress,
                route: routeWithUpdatedDate,
              });
              setShowStartOrderDialog(!showStartOrderDialog);
            }}
          />
          <DialogNotify
            show={showDeleteOrderDialog}
            isLoading={isLoading}
            icon={<DeleteOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="red300"
            buttonConfirmText={t("orders:all_orders.delete")}
            title={t("orders:all_orders.delete_title")}
            text={t("orders:all_orders.delete_description")}
            toggleModal={() => setShowDeleteOrderDialog(!showDeleteOrderDialog)}
            confirmButton={() => {
              deleteOrder();
              setShowDeleteOrderDialog(!showDeleteOrderDialog);
            }}
          />
          <DialogNotify
            show={showCancelOrderDialog}
            isLoading={isLoading}
            icon={<DeleteOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="red300"
            buttonConfirmText={t("orders:ongoing_orders.cancel")}
            title={t("orders:ongoing_orders.cancel_title")}
            text={t("orders:ongoing_orders.cancel_description")}
            toggleModal={() => setShowCancelOrderDialog(!showCancelOrderDialog)}
            confirmButton={() => {
              cancelOrder();
              setShowCancelOrderDialog(!showCancelOrderDialog);
            }}
          />
        </>
      )}
    </>
  );
};
